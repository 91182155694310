
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { mask } from 'vue-the-mask'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Component, Prop } from 'nuxt-property-decorator'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import DocFssp from '@pp-frontend/shared-packages/classes/doc-value/fssp/DocPerson'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import SearchFormAbstract from '@pp-frontend/shared-packages/classes/search-form/SearchFormAbstract'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Events } from '@pp-frontend/shared-packages/types/enum/Events'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import vSelect from 'vue-select'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import BaseInput from '~/components/UI/Input/BaseInput.vue'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import InputDate from '~/components/UI/Input/InputDate.vue'

@Component({
  components: {
  InputDate,
  BaseInput,
  vSelect
  },
  directives: {
  mask
  }
  })
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default class SearchFormFssp extends SearchFormAbstract {
  @Prop() showMeta!: boolean
  @Prop() isIframe!: boolean

  isError: string = ''
  errorDocVal: string = ''

  formData = new DocFssp()

  getType (): string {
    return 'fssp'
  }

  mounted () {
    this.$lastActivity.setEventListener(Events.SEARCH_FORM_SUBMIT)
    this.$eventBus.$on(Events.DATA_ERROR_FROM_VALIDATION, (error) => {
      this.errorDocVal = error.error.response.data
      this.isError = this.errorDocVal
    })
  }

  afterSubmitBehavior () {
    if (this.isIframe) {
      const params: any = Object.assign({
        document_type: this.formData.docType(),
        lead_source_id: this.$route.query.lead_source_id
      }, this.formData)
      const queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&')
      const url = `https://gos-oplata.ru/search/${this.getType()}?${queryString}`

      if (this.$route.query.target === 'parent') {
        // @ts-ignore
        window.parent.location = url
      } else {
        const win: any = window.open(url, '_blank')
        win.focus()
      }
    } else {
      this.$router.push({
        path: '/search/' + this.getType(),
        query: (Object.assign({
          document_type: this.formData.docType(),
          lead_source_id: window.$nuxt.$route.query.lead_source_id
        }, this.formData) as any)
      })
    }

    return Promise.resolve()
  }
}
