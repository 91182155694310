
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Component, Prop, Vue } from 'nuxt-property-decorator'
import qs from 'qs'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import LazyHydrate from 'vue-lazy-hydration'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import DocWidgetList from '@pp-frontend/shared-packages/components/Doc/WidgetList/DocWidgetList.vue'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import TabHeader from './TabHeader.vue'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import SearchFormGibdd from '~/components/search-form/SearchFormGibdd.vue'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import SearchFormFns from '~/components/search-form/SearchFormFns.vue'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import SearchFormFssp from '~/components/search-form/SearchFormFssp.vue'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import carTaxCard from '@pp-frontend/car-tax-widget/components/carTaxCard.vue'

@Component({
  components: {
  LazyHydrate,
  carTaxCard,
  SearchFormGibdd,
  SearchFormFssp,
  SearchFormFns,
  TabHeader,
  DocWidgetList
  }
  })
export default class extends Vue {
  @Prop({
    type: Boolean,
    default: true
  }) changeRoute!: boolean

  @Prop({
    type: Boolean,
    default: true
  }) showMeta!: boolean

  @Prop() isIframe!: boolean

  activeForm: number = 0
  allowServices: any = this.$allowServices()

  get searchType () {
    const types = {
      0: 'fssp',
      1: 'gibdd',
      2: 'fns'
    }
    return types[this.activeForm]
  }

  tabClick (id: string) {
    if (!this.changeRoute) {
      this.activateByRoute(id)
      return
    }
    const path = '/' + id + (Object.keys(this.$route.query).length ? '?' + qs.stringify(this.$route.query) : '')
    if (this.$route.fullPath !== path) {
      this.$router.replace(path)
    }
    this.activeFormChange(id)
  }

  isActive (num: number) {
    return this.activeForm === num
  }

  created () {
    this.activateByRoute(this.$route.path)
  }

  activeFormChange (id) {
    if (window.innerWidth < 769) {
      setTimeout(() => {
        let element = document.getElementById(id)
        let distance = 0
        if (element?.offsetParent) {
          do {
            distance += element.offsetTop
            // @ts-ignore
            element = element.offsetParent
          } while (element)
        }
        window.scrollTo({
          // @ts-ignore
          top: distance < 100 ? distance : (distance - 70),
          behavior: 'smooth'
        })
      }, 300)
    }
  }

  activateByRoute (id: string) {
    switch (id) {
      case 'fssp':
      case '/fssp':
        this.activeForm = 0
        break
      case 'gibdd':
      case '/gibdd':
        this.activeForm = 1
        break
      case 'fns':
      case '/fns':
        this.activeForm = 2
        break
      default:
        this.activeForm = 1
        break
    }
  }

  get title () {
    switch (this.$route.path) {
      case '/fssp':
        return 'Проверка и оплата задолженностей ФССП'
      case '/gibdd':
        return 'Проверка и оплата штрафов ГИБДД'
      case '/fns':
      default:
        return 'Проверка и оплата налоговой задолженности физических лиц'
    }
  }

  get description () {
    switch (this.$route.path) {
      case '/fssp':
        return 'Для проверки задолженностей ФССП введите ФИО, дату рождения и регион'
      case '/gibdd':
        return 'Для проверки штрафов ГИБДД введите номер СТС и гос. номер автомобиля, или номер водительского удостоверения, или же номер постановления'
      case '/fns':
      default:
        return 'Для проверки налоговых задолженностей достаточно ввести свой ИНН или паспортные данные'
    }
  }
}
