
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Component, Prop, Vue } from 'nuxt-property-decorator'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import Banner from '~/components/Banner.vue'

@Component({
  components: {
  Banner,
  guarantees: () => import('@pp-frontend/ui-kit/components/common/guarantees.vue'),
  Features: () => import('./Features.vue')
  }
  })
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default class container extends Vue {
  @Prop() isHideSidebar!: boolean
  @Prop() isShowBanner!: boolean
  type: string = 'gibdd'
  texts: Object = {
    gibdd: {
      guarantee: {
        title: 'Гарантия погашения',
        text: 'Данные об оплате автоматически передаются в базу данных ГИБДД через Федеральное Казначейство'
      },
      data: {
        title: 'Официальные данные',
        text: 'Поиск задолженностей осуществляется в базах данных Федерального казначейства и Государственной автоинспекции'
      },
      payment: {
        title: 'Моментальное погашение',
        text: 'Платежное поручение бесплатно отправляется на вашу электронную почту сразу после оплаты'
      },
      payment_type: {
        title: 'Безопасные платежи',
        text: 'Безопасность обеспечивается сквозным TLS-шифрованием и сертифицирована по стандарту PCI DSS'
      }
    },
    fns: {
      guarantee: {
        title: 'Гарантия погашения',
        text: 'Данные об оплате автоматически передаются в базу данных ФНС через Федеральное Казначейство'
      },
      data: {
        title: 'Официальные данные',
        text: 'Поиск задолженностей осуществляется в базах данных Федерального казначейства (ГИС ГМП)'
      },
      payment: {
        title: 'Моментальное погашение',
        text: 'Платежное поручение бесплатно отправляется на вашу электронную почту сразу после оплаты'
      },
      payment_type: {
        title: 'Безопасные платежи',
        text: 'Безопасность обеспечивается сквозным TLS-шифрованием и сертифицирована по стандарту PCI DSS'
      }
    },
    fssp: {
      guarantee: {
        title: 'Гарантия погашения',
        text: 'Данные об оплате автоматически передаются в базу данных ФССП России через Федеральное Казначейство'
      },
      data: {
        title: 'Официальные данные',
        text: 'Поиск задолженностей осуществляется в базах данных Федерального казначейства и судебных приставов'
      },
      payment: {
        title: 'Моментальное погашение',
        text: 'Платежное поручение бесплатно отправляется на вашу электронную почту сразу после оплаты'
      },
      payment_type: {
        title: 'Безопасные платежи',
        text: 'Безопасность обеспечивается сквозным TLS-шифрованием и сертифицирована по стандарту PCI DSS'
      }
    }
  }

  mounted () {
    if (this.$route.query.payment_type) {
      // @ts-ignore
      this.type = this.$route.query.payment_type || 'gibdd'
    }
    const types = {
      index: 'gibdd',
      'index-fns': 'fns',
      'index-gibdd': 'gibdd',
      'index-fssp': 'fssp',
      'search-gibdd': 'gibdd',
      'search-fns-esia': 'fns',
      'search-fns': 'fns',
      'search-fssp': 'fssp'
    }
    // @ts-ignore
    this.type = types[this.$route.name]
  }
}
